<template>
  <div class="lazy-image">
    <img :src="src" :alt="alt" />
  </div>
</template>
<script>
export default {
  name: 'LazyImage',
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.lazy-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}
</style>